<template>
<b-card :title="$t('customer_groups')">
  <b-row>
    <b-col md="12" class="my-1">
      <div class="text-right">
        <router-link :to="{ name: 'customerGroups.create' }" class="btn btn-sm btn-primary">{{ $t('add') }}</router-link>
      </div>
    </b-col>
  </b-row>
    <b-table responsive striped hover show-empty :items="items" :fields="fields" :current-page="currentPage" :per-page="0">
    <template v-slot:cell(status)="row">
      <span v-if="row.item.status === 1" class="badge badge-success">{{ $t('enabled') }}</span>
      <span v-else class="badge badge-danger">{{ $t('disabled') }}</span>
    </template>
    <template v-slot:cell(actions)="row">
        <router-link :to="{ name: 'customerGroups.edit', params: { 'id': row.item.id } }" class="btn btn-sm btn-warning mr-1"><fa icon="pencil-alt" /></router-link>
        <b-button size="sm" class="mr-1" variant="danger" @click="removeItem(row.item.id)">
          <fa icon="trash" />
        </b-button>
    </template>
    </b-table>
</b-card>
</template>
<script>
import axios from 'axios'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('customer_groups') }
    },
    data: () => ({
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Назва'
        },
        {
          key: 'created_at',
          label: 'Додано'
        },
        {
          key: 'status',
          label: 'Статус'
        },
        {
          key: 'actions',
          label: 'Дії'
        }
      ],
      currentPage: 1,
      perPage: 10,
      totalItems: 0
    }),
    mounted () {
        this.fetchData().catch(error => {
            console.log(error)
        })
    },
    methods: {
      async fetchData () {
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const { data } = await axios.get(apiUrl + 'customer-groups')
        this.items = data.data
      },
      async removeItem (id) {
        this.$root.$refs.loading.start()
        const apiUrl = process.env.VUE_APP_ENDPOINT_V1
        const result = confirm(this.$t('want_to_delete'))
        if (result) {
          await axios.delete(apiUrl + 'customer-groups/' + id)
          this.fetchData()
          this.$root.$refs.loading.finish()
        }
      }
    },
    watch: {
        currentPage: {
            handler: function (value) {
                this.fetchData().catch(error => {
                    console.log(error)
                })
            }
        }
    }
}
</script>
